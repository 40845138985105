const { ethers } = require("ethers");

const extraList = [
  // list of extra tokens here!
  {
    address: "0x8bf4646346c16E52a8400c35045Ae7058ecd4853",
    extraAllocation: 1000,
  },
  {
    address: "0xFa9b74DCe2b114F42D56B061Ba6a5C093864011C",
    extraAllocation: 500,
  },
  {
    address: "0xaf94cf32622e8034025df701ce2f3a7535e8eeed",
    extraAllocation: 500,
  },
  {
    address: "0x60565d99736a8291fd9568d0e34c04bd44f596eb",
    extraAllocation: 500,
  },
  {
    address: "0x78a9bc1eaae4f88cb94e2235ac8ce05fb345e841",
    extraAllocation: 500,
  },
  {
    address: "0xfb6380f49bd30da8ab95188086fd4f77ccaba6d4",
    extraAllocation: 500,
  },
  {
    address: "0x6683063160dfe1e7929137fb380ba1f911ca3fff",
    extraAllocation: 500,
  },
  {
    address: "0x4071da52be208af9b5db9cb5041d1107b82ac505",
    extraAllocation: 500,
  },
  {
    address: "0x17e167d89661faf7ed4f4d31901a4627a85ed9cd",
    extraAllocation: 500,
  },
  {
    address: "0x1341c8d121044a7e7f2c3bb5ea0dd9438570e70f",
    extraAllocation: 500,
  },
  {
    address: "0xc5e88651166f33274ad6b903b4d2fc55e2ecdc47",
    extraAllocation: 500,
  },
  {
    address: "0x083c828b221b126965a146658d4e512337182df1",
    extraAllocation: 500,
  },
  // here airdrop
  {
    address: "0x8AE9796AdE4cf200F7339658DacC63a67E092AD4",
    extraAllocation: 100,
  },
  {
    address: "0x51ec7a9546789936bc10a617e4c1fffb9ce847d5",
    extraAllocation: 100,
  },
  {
    address: "0x1B4aF6C26fFf9063102eba856cCe27C964e00c33",
    extraAllocation: 100,
  },
  {
    address: "0x1265d81418a802d54C2bf7Fac7b4DaCe0488496E",
    extraAllocation: 100,
  },
  {
    address: "0xdc2a600AFB7db9f7FD47DE454C4066F96ADD0869",
    extraAllocation: 100,
  },
  {
    address: "0x36fF9F8C2F600841b12cE7bfFE70984B48845E18",
    extraAllocation: 100,
  },
  {
    address: "0x92751E142bEe126A46c752394Fe5aaD84Af1A96E",
    extraAllocation: 100,
  },
  {
    address: "0x62dca3d13b4906c51b89d4aae3b1606430ad0294",
    extraAllocation: 100,
  },
  {
    address: "0x764b43bc8046336b263c6ac91052ddfd5c809cfb",
    extraAllocation: 100,
  },
  {
    address: "0x5545dba968da815848B912B87681a8c864c035F9",
    extraAllocation: 100,
  },
  {
    address: "0xC2616809596bEd74466Fbd7BbdaBC17b9e38CBE2",
    extraAllocation: 100,
  },
  {
    address: "0xD2B8Ea6bdC4b9967506C7b6C201FfD207e8Ae050",
    extraAllocation: 100,
  },
  {
    address: "0xcFb68EEF54B3d11A554f02ac8b97064Cc33F4d0E",
    extraAllocation: 100,
  },
  {
    address: "0xf6ae52067f50c2eb4713e87fb960fff10cd682ff",
    extraAllocation: 100,
  },
  {
    address: "0x202aad5ddd7e3b2fd24ecffdbe0471a8a89e782a",
    extraAllocation: 100,
  },
  {
    address: "0x07e65aeb246d300663a87a4a4318f5e32c05eaa7",
    extraAllocation: 100,
  },
  {
    address: "0x2f60cb17670a0e2985113108e9603dc131c64488",
    extraAllocation: 100,
  },
  {
    address: "0xd9ef054c85d919aff4dcd9d3381c989c9df4910a",
    extraAllocation: 100,
  },
  {
    address: "0xda9eaa83851e1ba5762aafe949a1131289f78fef",
    extraAllocation: 100,
  },
  {
    address: "0x5999aadf2edc1ff2419cc752ea96ba2b14e76eb7",
    extraAllocation: 100,
  },
  {
    address: "0x5462264F42fF98083840C62cBB2173962c646A86",
    extraAllocation: 100,
  },
  {
    address: "0x3e4Ead09B5341d050120B02109946C308a4215D8",
    extraAllocation: 100,
  },
  {
    address: "0x44a9075015E370F734852Faf0DC89CE6F2c93563",
    extraAllocation: 100,
  },
  {
    address: "0x4ae19322bf6de08308bdff6916d9f75ad093f65b",
    extraAllocation: 100,
  },
  {
    address: "0xbafae75d50e9382345808edcdc2d3a13d21bc1da",
    extraAllocation: 100,
  },
  {
    address: "0x9F4BA6A8bDD0A4319D7a9Dc92a8143C65243D67a",
    extraAllocation: 100,
  },
  {
    address: "0x92D6D7985cEfB9f199ADa951636850Dc02C2Da23",
    extraAllocation: 100,
  },
  {
    address: "0xE5B89d0C72aD3B8E8D1752F9cC6591F88D684F88",
    extraAllocation: 100,
  },
  {
    address: "0x7A22A5eD63aFC7A72B897bFDe1763f5549690a0f",
    extraAllocation: 100,
  },
  {
    address: "0x199E769393c011481D435AC6500e5A9049D0B0Ef",
    extraAllocation: 100,
  },
  {
    address: "0x2f520cAf9FBF25f35e50779E04a5510084E56743",
    extraAllocation: 100,
  },
  {
    address: "0x142e1dE9f670BCE9733eE3b048EF3301B7dd52f9",
    extraAllocation: 100,
  },
  {
    address: "0xb0A6d7d826Ba2517072ba947d8C7cdAFBCC46952",
    extraAllocation: 100,
  },
  {
    address: "0x1c66A525EB9c6887B9D77B175a99118F4A5F5f4f",
    extraAllocation: 100,
  },
  {
    address: "0x2Cf5B1Dfd795d660049615eA47d6DffE76591253",
    extraAllocation: 100,
  },
  {
    address: "0x1B3C8f1fC4F6Ba347076cB6e7a1fB18D93145eB7",
    extraAllocation: 100,
  },
  {
    address: "0xbBfDBB816854e85A3BDb46A90EB0753ccb8ACb7a",
    extraAllocation: 100,
  },
  {
    address: "0xAF876E5aED8aB69bBf71E36451D1Ecb25Bb92c46",
    extraAllocation: 100,
  },
  {
    address: "0x39D067826e1b5d32170F2a5e42Ad2B147c5E506C",
    extraAllocation: 100,
  },
  {
    address: "0x8284a2D8BABc06e22A66A1e62D7FD7D0c2c782fF",
    extraAllocation: 100,
  },
  {
    address: "0x9FD45A5a562582030f288D476bA7E4B2a67d8B95",
    extraAllocation: 100,
  },
  {
    address: "0xA3B5fDd90c8fF18949C94c541e258acd82493652",
    extraAllocation: 100,
  },
  {
    address: "0x0414D6142d27dc89e8280ae812322C1fBFe4dE7a",
    extraAllocation: 100,
  },
  {
    address: "0xf70fc3e3f048918b743B342971da71F8cdE73185",
    extraAllocation: 100,
  },
  {
    address: "0x2dfFBff11E225e1338b63C25a7598A8f0a15F1C7",
    extraAllocation: 100,
  },
  {
    address: "0x1766E70484FDE2bFEA5c6986A7769fe97AEfe7f1",
    extraAllocation: 100,
  },
  {
    address: "0xF2F9DFB52142a87CFfc687282a6dB8d580C05095",
    extraAllocation: 100,
  },
  {
    address: "0xE34621ac5C77a22F0Bb3110bf83087B7FcA853bD",
    extraAllocation: 100,
  },
  {
    address: "0xa4A3cdA55Aca021f265564bDe7962300C6dEaE89",
    extraAllocation: 100,
  },
  {
    address: "0x6cbc28A378d08384b26F7d7479F4d921CA970d06",
    extraAllocation: 100,
  },
  {
    address: "0xeE341e170bbE87be5663EEAf4E46B85FacaE47f6",
    extraAllocation: 100,
  },
  {
    address: "0x5814197E2C05ce53c95896351195a6b583547366",
    extraAllocation: 100,
  },
  {
    address: "0xaCe25E72F161f4cbeac8192e67ad148D05d77024",
    extraAllocation: 100,
  },
  {
    address: "0xE7B408c3616447F4A04d0A5C1FbCd8e05AbC5623",
    extraAllocation: 100,
  },
  {
    address: "0x7afEAddda01a5eb738245aC906f8feC25728F24B",
    extraAllocation: 100,
  },
  {
    address: "0x9E2893Fe22faB278642C1D53427f07f82B63e967",
    extraAllocation: 100,
  },
  {
    address: "0xF0559452407C719345d7424AFD3Cd56781057b90",
    extraAllocation: 100,
  },
  {
    address: "0xa0C215B7bFB8F4474383c475100f966EdfD3993f",
    extraAllocation: 100,
  },
  {
    address: "0xe0Cf4E94a4Fec9bDFd9F302e0D0FB4ba389fd825",
    extraAllocation: 100,
  },
  {
    address: "0xcb07ce8f4c90aa909116f8ef625ab867ee89f207",
    extraAllocation: 100,
  },
  {
    address: "0xe876a1469be728b83f9c6e382976b7C7707865BB",
    extraAllocation: 100,
  },
  {
    address: "0x68ed0f70ee276b99ecdeb9ab7a9fa445ad9fc9e9",
    extraAllocation: 100,
  },
  {
    address: "0x0b9fab58dd287895ab9ee880255fb15542f4aa66",
    extraAllocation: 100,
  },
  {
    address: "0xc5e57717c0901040b23327a2d412656f06c8e9d5",
    extraAllocation: 100,
  },
  {
    address: "0x4f28978bbaafaa8d34bac37169c2def9923ad619",
    extraAllocation: 100,
  },
  {
    address: "0x0973e9Be5d58F15bA1851e3221B1985896fedc2b",
    extraAllocation: 100,
  },
  {
    address: "0x49B4e789550a259D5cf9F6b44B05fAD8B28Df2eD",
    extraAllocation: 100,
  },
  {
    address: "0x7971f5F552C8fFFeD36fCaF307753e54D1d6a2AA",
    extraAllocation: 100,
  },
  {
    address: "0x87fB0860847878df108820ba8E671D3d70726f41",
    extraAllocation: 100,
  },
  {
    address: "0x9DB5bbCCA6987F733De0f1167210dd961C310E19",
    extraAllocation: 100,
  },
  {
    address: "0x1F60c91938dE6066A65c7C2fdaf84AE71691F0E0",
    extraAllocation: 100,
  },
  {
    address: "0x9A08400885fDc5F34923e3F6D74e75ed58D9907B",
    extraAllocation: 100,
  },
  {
    address: "0xF40870325973BC69D5A75B72D04093d93F9B8b4e",
    extraAllocation: 100,
  },
  {
    address: "0x2B37166B3561fD9DA6Bd35AF2a321155F3b30F59",
    extraAllocation: 100,
  },
  {
    address: "0x66a784ab330b79be6495ad3c53bf2cf18a63277c",
    extraAllocation: 100,
  },
  {
    address: "0x7A30b1C6D2bd093e0264d6e402121feeB605884f",
    extraAllocation: 100,
  },
  {
    address: "0x54A1DEF4F64E1e423DDB6787027e7ec59b32c408",
    extraAllocation: 100,
  },
  {
    address: "0x9f2B49d4C3Ca504593eF488Eb2f0b199115fC691",
    extraAllocation: 100,
  },
  {
    address: "0x375443011bCBd5c5362C6eF657923C578Fd1a124",
    extraAllocation: 100,
  },
  {
    address: "0x8e78f48eceaa5654a52c7a2ae6308b1ce06ff3e9",
    extraAllocation: 100,
  },
  {
    address: "0x2e45650A634eD6489CF135C71206137AA96dafF5",
    extraAllocation: 100,
  },
  {
    address: "0xa98CB6f2f2eEE05D496cc963517a3f30c6d6D262",
    extraAllocation: 100,
  },
  {
    address: "0x2e7319aBB13cA59FF4d17062f2b909fA54cd7d45",
    extraAllocation: 100,
  },
  {
    address: "0x71961dD1e170Fc9F59F8C6b5CAE0F02AB617bb9E",
    extraAllocation: 100,
  },
  {
    address: "0xde1F86C2775CCc76431979ff27Af78b24565a0C3",
    extraAllocation: 100,
  },
  {
    address: "0x9035F1b1c771fEa3C1DC27c40f368f0ba5De1694",
    extraAllocation: 100,
  },
  {
    address: "0xc552e60f4305fFeeF07a89Cc4513E67cb1fB7D2b",
    extraAllocation: 100,
  },
  {
    address: "0xD2161DB544eEFee89FC40e419Be031649C8E72cb",
    extraAllocation: 100,
  },
  {
    address: "0x48F4aed48B0B1671361b27bCC504EAB4793A88FC",
    extraAllocation: 100,
  },
  {
    address: "0x45ddC8bd5b77DC7210e47b5D928296244628C607",
    extraAllocation: 100,
  },
  {
    address: "0xd56bd52165bb22a42c18cd8017ef667f934b4285",
    extraAllocation: 100,
  },
  {
    address: "0x44fdb02060657259530c8b2D25263De3f74F8361",
    extraAllocation: 100,
  },
  {
    address: "0xe76044231604804917D3B43696983F49B47A2E78",
    extraAllocation: 100,
  },
  {
    address: "0xCC04c06AA11b0Cb09aaCabe585C3f3dEc7C092ae",
    extraAllocation: 100,
  },
  {
    address: "0x9b216aCE61541d7176Fd2916C0DC16f715A05373",
    extraAllocation: 100,
  },
  {
    address: "0xb1D915b5348ed88EA26f7eCc25C157F358249ffd",
    extraAllocation: 100,
  },
  {
    address: "0x78325488356eb76776399494328468989333730b",
    extraAllocation: 100,
  },
  {
    address: "0x1a90e5d9ef4dc854191379ec32d9008e8707cccf",
    extraAllocation: 100,
  },
  {
    address: "0x67cd278483c5c52ca466776b95f2971aba4f717b",
    extraAllocation: 100,
  },
  {
    address: "0x93ffb8286dc35cbdd7ab26f50955ba0898a65697",
    extraAllocation: 100,
  },
  {
    address: "0x9aaf81db3415b8865d314dc4bce0140faff35e9f",
    extraAllocation: 100,
  },
  {
    address: "0x29d9b3792e3d25372c44d4949796780ceaae0369",
    extraAllocation: 100,
  },
  {
    address: "0xbad0d0e21a9498fac80e723374ab645d0fefd7cb",
    extraAllocation: 100,
  },
  {
    address: "0xd3f2d431e31a695c59fdf57dee421979d478eb70",
    extraAllocation: 100,
  },
  {
    address: "0xDE383aCc3006c7F83B33CD6bAEd5958036d20C7f",
    extraAllocation: 100,
  },
  {
    address: "0xa84fEA620dF2D8CC00b3fC3A80482D848Bbe5Bb2",
    extraAllocation: 100,
  },
  {
    address: "0xe3964a75bC4B5D606c7B2b32A8eCAEF843e53B9E",
    extraAllocation: 100,
  },
  {
    address: "0x6B85Dd07614bC873C935045d15A10dD9111d3b8B",
    extraAllocation: 100,
  },
  {
    address: "0x39D067826e1b5d32170F2a5e42Ad2B147c5E506C",
    extraAllocation: 100,
  },
  {
    address: "0x49e03cA4cbcd5fA08a03Fd0D8696e61C6479a32B",
    extraAllocation: 100,
  },
  {
    address: "0x4f2961919408EEE1967fC09BDb251E62aB8589B7",
    extraAllocation: 100,
  },
  {
    address: "0x62aa16b2645f5919FF87A28Ee34D45fd36845C5d",
    extraAllocation: 100,
  },
  {
    address: "0xfd9f1474526d0306a20912B5b83D01d03Aa35738",
    extraAllocation: 100,
  },
  {
    address: "0x2bEcC80c2F2aA5f48d5543d9F7E8be0aA3C04A4a",
    extraAllocation: 100,
  },
  {
    address: "0x06b039BDb9db65Fd4D9A70E7402Ee301e8618246",
    extraAllocation: 100,
  },
  {
    address: "0x257b1824ca15ac5AD5f3a09e2504a1b9EE26934b",
    extraAllocation: 100,
  },
  {
    address: "0x70d3dB11D578B7F189374C735309487deBa4Be03",
    extraAllocation: 100,
  },
  {
    address: "0x44604a78d5f9734992acbed00724566623687f1a",
    extraAllocation: 100,
  },
  {
    address: "0x1ca2fd514f79112b91c508b4ec4470e028a04d5a",
    extraAllocation: 100,
  },
  {
    address: "0xF10666f72e13f355A5d44301Db1D8e9C4Afdc0d9",
    extraAllocation: 100,
  },
  {
    address: "0xBe13fACCc7FcA7ac6b79f4E4942423468255090a",
    extraAllocation: 100,
  },
];

export async function getBalance(address, providerRpc, wallet) {
  const ABITokens = require("./others/usABI.json");
  const provider = new ethers.JsonRpcProvider(providerRpc);
  const tokenContract = new ethers.Contract(address, ABITokens, provider);
  const result = parseInt(
    await tokenContract.pointsBalance(wallet).then((response) => response)
  );
  return result / 10 ** 18;
}

function checkIfExtra(userAddress) {
  for (let i = 0; i < extraList.length; i++) {
    if (extraList[i]["address"] == userAddress) {
      return extraList[i]["extraAllocation"];
    }
  }
  return 0;
}

export async function getPointsAirdrop(user) {
  let tokensBalance = 0;
  let added = checkIfExtra(user);
  const ABIAirdrop = [
    "function pointsBalance(address)external view returns(uint256)",
  ];
  // POLYGON
  let provider = new ethers.JsonRpcProvider("https://rpc.ankr.com/polygon");
  let tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // BSC
  provider = new ethers.JsonRpcProvider("https://bsc-rpc.publicnode.com");
  tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // BASE
  provider = new ethers.JsonRpcProvider(
    "https://base.rpc.subquery.network/public"
  );
  tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // OP
  provider = new ethers.JsonRpcProvider(
    "https://optimism-mainnet.public.blastapi.io"
  );
  tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // CELO
  provider = new ethers.JsonRpcProvider("https://rpc.ankr.com/celo");
  tokenContract = new ethers.Contract(
    "0x95f54C8a157ED563A0b5Fe410C7B5E4106a8E306",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // ETH
  provider = new ethers.JsonRpcProvider("https://ethereum-rpc.publicnode.com");
  tokenContract = new ethers.Contract(
    "0x95f54C8a157ED563A0b5Fe410C7B5E4106a8E306",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  return tokensBalance / 1e18 + added;
}
