import "./style.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { desktopOS, valueFormatter } from "./airdropInfo.ts";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import Token from "../../images/utopLogo.png";
import { getPointsAirdrop } from "./functions.js";

const Airdrop = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [balance, setBalance] = React.useState(0.0);
  const wallet = useSelector((state) => state.User.wallet);
  const chain = useSelector((state) => state.User.chain);
  const airdropRedux = useSelector((state) => state.User.airdrop);
  const dispatch = useDispatch();
  const changeSwap = React.useCallback(async () => {
    dispatch({
      type: "changeWallet",
      CurrentWallet: wallet,
      isLogged: true,
      chain: chain,
      airdrop: 0,
    });
  });

  useEffect(() => {
    async function getBalance() {
      if (airdropRedux != 0) {
        await changeBalance();
        await changeSwap();
      }
    }
    getBalance();
  }, [airdropRedux]);

  const changeBalance = async () => {
    let balance = await getPointsAirdrop(wallet);
    setBalance(balance);
  };

  React.useEffect(() => {
    if (wallet != "") changeBalance();
  }, [wallet]);

  const handleChange = () => {};

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div>
        <h3 className="mainCountdown">
          <span className="numbersCountdown">{days}</span>:
          <span className="numbersCountdown">{hours}</span>:
          <span className="numbersCountdown">{minutes}</span>:
          <span className="numbersCountdown">{seconds}</span>
        </h3>
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="divAirdrop">
        <button
          className="reward-btn"
          style={{ borderRadius: wallet != "" ? "8px 0px 0px 8px" : "8px" }}
          onClick={handleOpen}
        >
          <span className="IconContainer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 60 20"
              className="box-top box"
            >
              <path
                strokeLinecap="round"
                strokeWidth="4"
                stroke="#6A8EF6"
                d="M2 18L58 18"
              ></path>
              <circle
                strokeWidth="5"
                stroke="#6A8EF6"
                fill="#101218"
                r="7"
                cy="9.5"
                cx="20.5"
              ></circle>
              <circle
                strokeWidth="5"
                stroke="#6A8EF6"
                fill="#101218"
                r="7"
                cy="9.5"
                cx="38.5"
              ></circle>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 58 44"
              className="box-body box"
            >
              <mask fill="white" id="path-1-inside-1_81_19">
                <rect rx="3" height="44" width="58"></rect>
              </mask>
              <rect
                mask="url(#path-1-inside-1_81_19)"
                strokeWidth="8"
                stroke="#6A8EF6"
                fill="#101218"
                rx="3"
                height="44"
                width="58"
              ></rect>
              <line
                strokeWidth="6"
                stroke="#6A8EF6"
                y2="29"
                x2="58"
                y1="29"
                x1="-3.61529e-09"
              ></line>
              <path
                strokeLinecap="round"
                strokeWidth="5"
                stroke="#6A8EF6"
                d="M45.0005 20L36 3"
              ></path>
              <path
                strokeLinecap="round"
                strokeWidth="5"
                stroke="#6A8EF6"
                d="M21 3L13.0002 19.9992"
              ></path>
            </svg>
            <span className="coin"></span>
          </span>
          <span className="text">Airdrop</span>
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="boxAirdrop">
          <div className="headerAirdropPart">
            <h1 className="headerAirdrop">$UTOPIA</h1>
            <img src={Token} className="logoUtopia" />
          </div>
          <p className="textAidropUtopia">
            For every dollar you trade in Utopia Swap during the airdrop
            phase,you will get 1 $UTOPIA token,allowing you to get exposed in
            the business side of Utopia,by getting protocol fees from others
            swaps and decide the future of the platform thanks to the governance
            DAO ready to get deployed early 2025.
          </p>
          <div>
            <h3 className="eligibleText">DISTRIBUTION</h3>
            <PieChart
              series={[
                {
                  data: desktopOS,
                  highlightScope: { fade: "global", highlight: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                  valueFormatter,
                },
              ]}
              slotProps={{
                legend: {
                  hidden:
                    window.screen.width < 1130 && window.screen.width > 800
                      ? true
                      : false,
                  labelStyle: {
                    fill: "wheat",
                  },
                },
              }}
              colors={["#fcbf49", "#ff7f3f", "#ff2c2a", "#e79b07"]}
              height={200}
              style={{
                marginLeft:window.screen.width < 1130 && window.screen.width > 800 ? "39px" : "-30px"
              }}
            />
          </div>
          <p className="textAidropUtopia">
            after the distribution we'll proceed with the listing in the
            following exchanges: KuCoin,Bitstamp and uniswapV3,fullfilling the
            CEX and DEX needs of the community.<br></br>After this,we'll use the
            marketing/listing fund to get the token listed in more exchanges.
          </p>
          <Countdown renderer={renderer} date={"20 Apr 2025 4:20:00 GMT"} />
          <p className="textBottomAirdrop">END OF AIRDROP PHASE</p>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "-6px",
            }}
          >
            <p style={{ color: "#c3ad83" }}>
              your allocation:
              <h4 style={{ color: "wheat" }}>{balance.toFixed(2)} $UTOPIA</h4>
            </p>
          </span>
          <div style={{ marginTop: "-20px" }}></div>
        </Box>
      </Modal>
      {wallet != "" ? (
        <div>
          <div className="customAirdropBalanceMain">
            <div className="customAirdropBalanceDiv">
              <img src={Token} className="logoUtopiaAirdrop" />
              <h4 className="textBalanceAirdrop">{balance.toFixed(2)}</h4>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Airdrop;
